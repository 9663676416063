import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import ReactHtmlParser from "react-html-parser";

import { AppWrap, MotionWrap } from "../../wrapper";
// import { images } from "../../constants";
import "./About.scss";
import { urlFor, client } from "../../client";
import { ERROR_MESSAGE } from "../errorMessage";
import "../../container/errorMessages.scss";

// static method without sanity
// const abouts = [
//   {
//     title: "Web Development",
//     description: "I'm a good developer",
//     imgUrl: images.about01,
//   },
//   {
//     title: "Web Design",
//     description: "I'm a good developer",
//     imgUrl: images.about02,
//   },
//   {
//     title: "UI/UX",
//     description: "I'm a good developer",
//     imgUrl: images.about03,
//   },
//   {
//     title: "Web Animations",
//     description: "I'm a good developer",
//     imgUrl: images.about04,
//   },
// ];

const About = () => {
  const [abouts, setAbouts] = useState([]);
  const [hasError, setHasError] = useState(false);

  // initial load useEffect -- fetching abouts from sanity
  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client
      .fetch(query)
      .then((data) => {
        // console.log(data);
        setAbouts(data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setHasError(true);
      });
  }, []);

  return (
    <>
      <h2 className="head-text">
        I Know That <span>Good Development</span> <br /> means{" "}
        <span>Good Business</span>
      </h2>

      <div className="app__profiles">
        {hasError ? (
          <p className="app__content-error">{ReactHtmlParser(ERROR_MESSAGE)}</p>
        ) : (
          abouts?.map((about, index) => (
            <motion.div
              whileInView={{ opacity: 1 }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5, type: "tween" }}
              className="app__profile-item"
              key={about.title + index}
            >
              <img src={urlFor(about.imgUrl)} alt={about.title} />
              <h2 className="bold-text" style={{ marginTop: 20 }}>
                {about.title}
              </h2>
              <p className="p-text" style={{ marginTop: 10 }}>
                {about.description}
              </p>
            </motion.div>
          ))
        )}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
