import email from "../assets/email.png";
import mobile from "../assets/mobile.png";
import api from "../assets/api.png";
import cpp from "../assets/cpp.png";
import css from "../assets/css.png";
import figma from "../assets/figma.png";
import flutter from "../assets/flutter.png";
import git from "../assets/git.png";
import graphql from "../assets/graphql.png";
import html from "../assets/html.png";
import java1 from "../assets/Java_logo.png";
import java2 from "../assets/Java_logo-2.png";
import javascript from "../assets/javascript.png";
import mu5 from "../assets/mu5.png";
import node from "../assets/node.png";
import python from "../assets/python.png";
import react from "../assets/react.png";
import redux from "../assets/redux.png";
import sass from "../assets/sass.png";
import springboot from "../assets/spring-boot-logo.png";
import typescript from "../assets/typescript.png";
import vue from "../assets/vue.png";

// import about01 from "../assets/about01.png";
// import about02 from "../assets/about02.png";
// import about03 from "../assets/about03.png";
// import about04 from "../assets/about04.png";

import profile from "../assets/profile.png";
import circle from "../assets/circle.svg";
// import logo from "../assets/logo.png";
import patrickKellLogo from "../assets/patrickKellLogo.png";
import patrickKellLogo2 from "../assets/patrickKellLogo2.png";

import adidas from "../assets/adidas.png";
import amazon from "../assets/amazon.png";
import asus from "../assets/asus.png";
import bolt from "../assets/bolt.png";
import nb from "../assets/nb.png";
import skype from "../assets/skype.png";
import spotify from "../assets/spotify.png";

export default {
  email,
  mobile,
  api,
  cpp,
  css,
  figma,
  flutter,
  git,
  graphql,
  html,
  java1,
  java2,
  javascript,
  mu5,
  node,
  python,
  react,
  redux,
  springboot,
  sass,
  typescript,
  vue,
  profile,
  circle,
  patrickKellLogo,
  patrickKellLogo2,
  adidas,
  amazon,
  asus,
  bolt,
  nb,
  skype,
  spotify,
};
